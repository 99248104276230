import React, { useState, useCallback } from 'react';
import { StaticQuery, graphql, Link } from "gatsby"
import PhotoPortfolio from '../sections/PhotoPortfolio';
import PhotographyCard from '../cards/PhotographyCard';

// export const PhotographyPageQuery = graphql`
// {
//   photographyPosts: allGhostPost(filter: {tags: {elemMatch: {name: {regex: "/#+/"}}}}) {
//     edges {
//       node {
//         ...GhostPostFields
//       }
//     }
//   }
// }
// `


const PhotographyPosts = props => (
  <StaticQuery
    query={graphql`
      {
        allGhostPost(filter: {tags: {elemMatch: {name: {regex: "/#+/"}}}}) {
          edges {
            node {
              ...GhostPostFields
            }
          }
        }
      }
    `}
    render={data => <Photography data={data} {...props}/>}
  ></StaticQuery>
)

const Photography = ({ data }) => {
  let photographyPosts
  photographyPosts = data.allGhostPost.edges
  
  return(
      <div id="photography-page">
        <h2 className="page-section-title"> Our Photography </h2>
        <section className="post-feed">
            {photographyPosts.map(({ node }) => (
                <PhotographyCard key={node.id} node={node} />
            ))}
        </section>
        </div> 
  );
};

export default PhotographyPosts;
