import React from 'react';
import Layout from '../../../components/layout/defaultlayout'
import { CategoriesAuthors, Construction } from '../../../components/common/pages'; 

const authors = () => {
      return(
        <>
        <Layout isHome={false}>
          <CategoriesAuthors/>
        </Layout>
        </>
      );
};

export default authors;
