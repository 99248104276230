import React from 'react';
import { StaticQuery, graphql, Link} from 'gatsby'
const { siteBlogPath } = require(`../../../../utils/configs/ghost`)
import { AuthorCard } from '../../cards';


const GhostAuthors = props => (
  <StaticQuery
    query={graphql`
      {
        allGhostAuthor(sort: { order: ASC, fields: name }) {
          edges {
              node {
                name
                profile_image
                slug
              }
          }
        }
      }
    `}
    render={data => <Authors data={data} {...props}/>}
  ></StaticQuery>
)

const Authors = ({ data }) => {
  const authors = data.allGhostAuthor.edges
  return(
    <div id="authors" className="container">
      <h2 className="authors-title">Getting to know the authors </h2>
      <hr/>
      <br/>
      <section className="authors-feed">
          {authors && authors.map((author) => (
                <AuthorCard name={author.node.name} photo={author.node.profile_image} url={siteBlogPath + "/author/" + author.node.slug}/>
          ))}
        </section>
  </div>
  );
};
export default GhostAuthors;