import React from 'react';
import { StaticQuery, graphql, Link} from 'gatsby'
const { siteBlogTagPath } = require(`../../../../utils/configs/ghost`)
const filterOutInternalTags = require(`../../../../utils/filters`).filterOutInternalTags


const GhostTags = props => (
    <StaticQuery
      query={graphql`
        {
          allGhostTag {
            edges {
              node {
                slug
                name
                postCount
              }
            }
          }
        }
      `}
      render={data => <Tags data={data} {...props}/>}
    ></StaticQuery>
  )

const Tags = ({ data }) => {
  const tags = data.allGhostTag.edges.filter(filterOutInternalTags)
  return(
      <>
      <div className="tags">
        <h2 className="tag-title">Search for articles based on tags</h2>
        <hr/>
        <br/>
        <section className="tag-feed">
          {tags && tags.map((tag) => (
              <Link className="tag" to={ siteBlogTagPath + "/" + tag.node.slug}>
                {tag.node.name}
                <sup> {tag.node.postCount}</sup>
              </Link>
          ))}
        </section>
      </div>
      </>

  );
};

export default GhostTags;
