import React from 'react';
import { StaticQuery, graphql, Link} from 'gatsby'
import { ClickMeCard } from '../cards'
import { pagesConfig } from '../../../utils/configs/site';
import IMG_CLICKME from "../../../assets/images/clickme.jpeg"
import { shortTitle } from '../../../utils/configs/ghost';

const ClickMe = ({ navigation }) => {
  if(!navigation) navigation = pagesConfig.clickme.navigation;
  return(
    <div className="click-me">
      {/* TODO: Change so when a user clicks on avaater they will be prompt to view more photos */}
      <div className="avatar">
        <img className="avatar" src={IMG_CLICKME} alt={"Click Me Page Avatar"}/>
      </div>
      <h1 className="clickme-title"> {shortTitle} </h1>
      <section className="clickme-section">
        {navigation && navigation.map((element) => (
           <ClickMeCard title={element.key} icon={element.icon} url={element.url}/>
        ))}
      </section>  
    </div>
    );
};

export default ClickMe;
