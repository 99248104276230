import React from 'react';

const Construction = () => {
  return(
    <div id="construction-page" className="container" style={{textAlign:"center", textTransform:"capitalize"}}>
    <h1>😖 This page is still being coded back at the lab 😖</h1>
    <h1>🥸 Enjoy the following emojis 🥸</h1>
    <h3>
      (>‿◠)✌
      &ensp;
      (⊙.⊙(☉̃ₒ☉)⊙.⊙)
      &ensp;
      (　-_･) ︻デ═一 ▸
      &ensp;
      ♪┏(・o･)┛♪
      &ensp;
      ヽ(⌐■_■)ノ♪♬
    </h3>
    <h3>
      (>‿◠)✌
      &ensp;
      (⊙.⊙(☉̃ₒ☉)⊙.⊙)
      &ensp;
      (　-_･) ︻デ═一 ▸
      &ensp;
      ♪┏(・o･)┛♪
      &ensp;
      ヽ(⌐■_■)ノ♪♬
    </h3>
    <h3>
      (>‿◠)✌
      &ensp;
      (⊙.⊙(☉̃ₒ☉)⊙.⊙)
      &ensp;
      (　-_･) ︻デ═一 ▸
      &ensp;
      ♪┏(・o･)┛♪
      &ensp;
      ヽ(⌐■_■)ノ♪♬
    </h3>
  </div>
  );
};

export default Construction;
