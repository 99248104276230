import React from 'react'
import { Link } from 'gatsby'
import { sitePostPath } from '../../../utils/configs/ghost'


const PhotographyCard = ({node}) => {
  const url = sitePostPath + `/${node.slug}/`
  let codeInjectionHead, featureImage;

  /**
   * This conditional statement verify  a feature image will be availble for a post if Ghost CMS does not provide one
   * 
   * @fix this could lead to problems in the future if a codeinjection_head is used different;y from a photography post
   */
  if(!featureImage && node.codeinjection_head){
    codeInjectionHead = eval(node.codeinjection_head)
    if(codeInjectionHead[0]){
        featureImage = codeInjectionHead[0].src
    }
  }else{
      featureImage = node.feature_image
  }


  return (
    // url("https://static.ghost.org/v4.0.0/images/publishing-options.png")
    <Link to={url} className="post-card">
      <div className="photography-card">
          <div className="photography-card-header">
              <div className="photography-card-image" style={{backgroundImage: `url(${featureImage})`,}}/>
              <div className="photography-card-title"> {node.title} </div> 
          </div>
      </div>
    </Link>
  )
}

export default PhotographyCard